<div fxLayout="column" fxLayoutAlign="center center" fxFill>
  <mat-card class="login-card" fxLayout="column" fxLayoutAlign="center center">
    <app-form-header
      [text]="
        'register.info' | transloco: { accountService: accountServiceTitle }
      "
    ></app-form-header>
    <form
      class="full-width"
      fxLayout="column"
      fxLayoutAlign="space-around"
      fxLayoutGap="10px"
    >
      <mat-form-field class="input-block" appearance="outline">
        <mat-label>{{ 'login.email' | transloco }}</mat-label>
        <input
          id="email-input"
          matInput
          type="email"
          #userName
          [appAutofocus]
          [formControl]="usernameFormControl"
          [errorStateMatcher]="matcher"
          (blur)="activateValidators()"
        />
        <mat-error
          *ngIf="
            usernameFormControl.hasError('email') &&
            !usernameFormControl.hasError('required')
          "
        >
          {{ 'login.email-invalid' | transloco }}
        </mat-error>
        <mat-error *ngIf="usernameFormControl.hasError('required')">
          {{ 'login.email-required' | transloco }}
        </mat-error>
      </mat-form-field>

      <app-password-entry
        [checkStrength]="true"
        [isNew]="true"
      ></app-password-entry>

      <mat-checkbox
        [(ngModel)]="acceptToS"
        [disabled]="formDisabled"
        name="accept"
      >
        {{ 'register.accept' | transloco
        }}<a href="{{ linkOfToS }}" target="_blank">{{
          'register.terms' | transloco
        }}</a
        >{{ 'register.accept-2' | transloco }}
      </mat-checkbox>
      <app-loading-button
        name="register.register"
        [fullWidth]="true"
        (clicked)="register(userName.value)"
      ></app-loading-button>
      <div fxLayout="row" fxLayoutAlign="center">
        <p class="smaller-text">
          {{ 'register.already-registered' | transloco }}
        </p>
        <a
          class="action-link after-text-link"
          [routerLink]="'/login'"
          aria-labelledby="register"
        >
          {{ 'login.login' | transloco }}</a
        >
      </div>
    </form>
  </mat-card>
</div>
