<div fxFill fxLayout="column" fxLayoutAlign="space-between center">
  <div class="container">
    <div
      fxLayout.xs="column"
      fxLayout.gt-xs="row wrap"
      fxLayoutGap="1em"
      fxLayoutAlign="space-between"
    >
      <mat-tab-group
        (selectedTabChange)="switchList($event)"
        [selectedIndex]="showPublic ? 0 : 1"
        [disablePagination]="true"
      >
        <mat-tab [label]="'templates.public-templates' | transloco"> </mat-tab>
        <mat-tab [label]="'templates.my-templates' | transloco"> </mat-tab>
      </mat-tab-group>
      <app-template-language-selection
        *ngIf="showPublic"
        fxFlex.xs="100"
        [defaultLang]="selectedLang"
        [smaller]="true"
        (selectedLangChanged)="updateLanguage($event)"
      ></app-template-language-selection>
    </div>

    <div class="selection-container" fxLayout="column" fxLayoutGap="1em">
      <app-template-tag-selection
        *ngIf="showPublic"
        (selectedTagsChanged)="updateTags($event)"
        [lang]="selectedLang"
        [langChanged]="langChanged"
        [selectedTags]="selectedTags"
        [selectedTagIds]="tagIdsQueryParams"
      ></app-template-tag-selection>
      <div fxLayout="column" fxLayoutGap="1em">
        <app-loading-indicator *ngIf="loadingTemplates"></app-loading-indicator>
        <div
          *ngIf="!loadingTemplates && templates.length === 0"
          fxLayoutAlign="center"
        >
          <p class="hint">{{ 'templates.no-templates-found' | transloco }}</p>
        </div>
        <div fxLayout="column" fxLayoutGap="10px">
          <app-content-group-template
            *ngFor="let template of templates"
            [template]="template"
            [room]="room"
            (previewClicked)="showPreview($event)"
          ></app-content-group-template>
        </div>
      </div>
    </div>
  </div>
</div>
