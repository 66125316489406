<div fxLayout="column" fxLayoutAlign="center center" fxFill>
  <mat-card class="login-card" fxLayout="column" fxLayoutAlign="center center">
    <form (ngSubmit)="resetPassword()" fxLayout="column">
      <mat-form-field class="input-block" appearance="outline">
        <mat-label>{{ 'login.email' | transloco }}</mat-label>
        <input
          matInput
          type="email"
          #email
          [(ngModel)]="username"
          [formControl]="usernameFormControl"
          [errorStateMatcher]="matcher"
          name="email"
          (blur)="activateValidators()"
        />
        <mat-error
          *ngIf="
            usernameFormControl.hasError('email') &&
            !usernameFormControl.hasError('required')
          "
        >
          {{ 'login.email-invalid' | transloco }}
        </mat-error>
        <mat-error *ngIf="usernameFormControl.hasError('required')">
          {{ 'login.email-required' | transloco }}
        </mat-error>
      </mat-form-field>
      <p class="info">{{ 'password-reset.forgot-password' | transloco }}</p>
      <app-loading-button
        name="password-reset.reset-password"
        [fullWidth]="true"
      ></app-loading-button>
    </form>
  </mat-card>
</div>
