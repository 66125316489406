<div
  tabindex="-1"
  class="container"
  [ngClass]="{ 'fixed-width-container': fixedWitdth }"
  (touchstart)="swipe($event, 'start')"
  (touchend)="swipe($event, 'end')"
>
  <div fxLayout="row" class="inner-container" *ngIf="showSteps">
    <button
      tabindex="-1"
      mat-icon-button
      *ngIf="listLength > 5"
      disableRipple
      [disabled]="headerPos < 1"
      (click)="moveHeaderRight(true)"
    >
      <mat-icon color="primary" [ngClass]="{ disabled: headerPos < 1 }"
        >keyboard_arrow_left</mat-icon
      >
    </button>
    <div
      [ngClass]="{
        'fixed-width-container container': listLength <= 5,
        'stepper-container': listLength > 5
      }"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="start"
        [@slideHeader]="{
          value: headerAnimationState,
          params: { position: headerPos * -20 }
        }"
        (@slideHeader.done)="headerAnimationDone()"
      >
        <span class="fill-remaining-space"></span>
        <div
          class="step-container"
          *ngFor="let step of steps; let i = index"
          (click)="onClick(i)"
        >
          <button
            class="step"
            tabindex="-1"
            [ngClass]="{
              'primary-background primary-border': selectedIndex === i,
              'primary primary-border': completed.get(i) && selectedIndex !== i,
              last: i === listLength - 1
            }"
          >
            <span *ngIf="!additionalStepIcon || i < listLength - 1">{{
              i + 1
            }}</span>
            <mat-icon *ngIf="additionalStepIcon && i === listLength - 1">{{
              additionalStepIcon
            }}</mat-icon>
          </button>
        </div>
        <span class="fill-remaining-space"></span>
      </div>
    </div>
    <button
      mat-icon-button
      tabindex="-1"
      *ngIf="listLength > 5"
      disableRipple
      [disabled]="headerPos >= listLength"
      (click)="moveHeaderLeft(true)"
    >
      <mat-icon
        color="primary"
        [ngClass]="{ disabled: headerPos >= listLength - 5 }"
        >keyboard_arrow_right</mat-icon
      >
    </button>
  </div>
  <div
    fxFill
    [@slideContainer]="containerAnimationState"
    (@slideContainer.done)="containerAnimationDone()"
  >
    <ng-container [ngTemplateOutlet]="selected?.content || null"></ng-container>
  </div>
</div>
