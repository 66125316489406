export enum ContentType {
  CHOICE = 'CHOICE',
  SCALE = 'SCALE',
  BINARY = 'BINARY',
  TEXT = 'TEXT',
  WORDCLOUD = 'WORDCLOUD',
  SORT = 'SORT',
  PRIORITIZATION = 'PRIORITIZATION',
  NUMERIC = 'NUMERIC',
  SLIDE = 'SLIDE',
  FLASHCARD = 'FLASHCARD',
}
