<div mat-dialog-content>
  <form
    (ngSubmit)="login(userActivationKey.value)"
    fxLayout="column"
    fxLayoutAlign="space-around"
    fxLayoutGap="10px"
  >
    <mat-form-field class="input-block" appearance="outline">
      <mat-label>{{ 'user-activation.key' | transloco }}</mat-label>
      <input
        matInput
        #userActivationKey
        type="text"
        [formControl]="activationKeyFormControl"
        name="activation-key"
        aria-labelledby="key-input"
      />
    </mat-form-field>
  </form>
  <p tabindex="0">
    {{ 'user-activation.verification-info' | transloco }}
  </p>
  <div fxLayout="row" fxLayoutAlign="start center">
    <p>{{ 'user-activation.didnt-get' | transloco }}</p>
    <button
      mat-button
      color="accent"
      class="reset"
      (click)="
        resetActivation(); $event.preventDefault(); $event.stopPropagation()
      "
    >
      {{ 'user-activation.reset-button' | transloco }}
    </button>
  </div>
</div>
<div mat-dialog-actions fxLayoutAlign="start" fxLayout="row-reverse">
  <app-loading-button
    name="user-activation.activate"
    [isDialog]="true"
    (clicked)="login(userActivationKey.value)"
  ></app-loading-button>
  <button mat-button (click)="cancel()">
    {{ 'user-activation.cancel' | transloco }}
  </button>
</div>

<div class="visually-hidden">
  <div id="key-input">{{ 'user-activation.a11y-key-input' | transloco }}</div>
</div>
