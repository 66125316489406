<div id="footer-toolbar" fxLayout="row wrap" fxLayoutAlign="space-between">
  <lib-extension-point extensionId="footer-reference">
    <div fxLayout="row" fxLayoutAlign="center center" fxFill>
      <a [attr.href]="referenceUrl" target="_blank">
        <span class="powered-by">{{ 'footer.powered-by' | transloco }}</span>
        <span class="by">{{ 'footer.by' | transloco }}</span>
        <span>Particify</span>
      </a>
    </div>
  </lib-extension-point>
  <app-footer-links
    *ngIf="showFooterLinks && uiConfig"
    [uiConfig]="uiConfig"
  ></app-footer-links>
</div>
