<div mat-dialog-content>
  <h3 tabindex="0" aria-labelledby="update-header" class="secondary">
    {{
      (afterUpdate ? 'home-page.update-made' : 'home-page.update-available')
        | transloco
    }}
  </h3>
  <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
  <div *ngIf="!isLoading">
    <div *ngIf="changes">
      <p *ngIf="newsUrl" tabindex="0">
        {{ 'home-page.see-news' | transloco
        }}<a href="{{ newsUrl }}">{{ 'home-page.news' | transloco }}</a
        >.
      </p>
      <p>{{ 'home-page.important-changes' | transloco }}</p>
      <ul tabindex="0">
        <li *ngFor="let change of changes">{{ change }}</li>
      </ul>
    </div>
    <p class="hot-fix" *ngIf="!changes" tabindex="0">
      {{ 'home-page.hot-fix-update' | transloco }}
    </p>
  </div>
</div>
<div mat-dialog-actions fxLayoutAlign="end center">
  <app-loading-indicator
    *ngIf="!afterUpdate && !updateReady"
    [size]="24"
    height="auto"
  ></app-loading-indicator>
  <button
    mat-flat-button
    color="primary"
    aria-labelledby="update-button"
    [disabled]="!afterUpdate && !updateReady"
    (click)="close()"
  >
    {{ (afterUpdate ? 'dialog.close' : 'home-page.update') | transloco }}
  </button>
  <div class="visually-hidden">
    <div id="update-header">
      {{
        'home-page.a11y-update-' + (afterUpdate ? 'made' : 'available')
          | transloco
      }}
    </div>
    <div id="update-button">
      {{ 'home-page.a11y-' + (afterUpdate ? 'close' : 'update') | transloco }}
    </div>
  </div>
</div>
