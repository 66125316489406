<mat-card
  fxLayout="column"
  fxLayoutAlign="space-between"
  tabindex="0"
  class="less-bottom-space"
>
  <div>
    <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em">
      <span class="template-name">{{ template.name }}</span>
      <div
        fxLayoutAlign="center center"
        class="primary-variant content-count-container"
      >
        <span class="on-primary-variant content-count"
          >{{ template.templateIds.length }}
          <span *ngIf="template.templateIds.length === 1">{{
            'templates.content' | transloco
          }}</span>
          <span *ngIf="template.templateIds.length > 1">{{
            'templates.contents' | transloco
          }}</span>
        </span>
      </div>
    </div>
    <p class="ellipsis template-description">
      {{ template.description }}
    </p>
  </div>
  <div fxLayout="column" fxLayoutGap="0.5em">
    <app-template-license
      [ownerName]="template.attribution"
      [license]="template.license"
      [aiGenerated]="template.aiGenerated"
    ></app-template-license>
    <div fxLayout="row" fxLayoutAlign="start center">
      <mat-chip-set class="chip-container no-flex-flow">
        <mat-chip *ngFor="let tag of template.tags">
          {{ tag.name }}
        </mat-chip>
      </mat-chip-set>
      <div fxLayout="row" fxLayoutAlign="end center" class="fade-container">
        <div class="button-fade"></div>
        <div class="button-container" fxLayout="row" fxLayoutGap="10px">
          <button mat-stroked-button (click)="preview()">
            {{ 'templates.preview' | transloco }}
          </button>
          <app-add-template-button
            [templateId]="template.id"
            [templateName]="template.name"
            [room]="room"
          ></app-add-template-button>
        </div>
      </div>
    </div>
  </div>
</mat-card>
