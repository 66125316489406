<h2 mat-dialog-title>{{ 'dialog.select-room' | transloco }}</h2>
<mat-dialog-content>
  <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
  <mat-action-list *ngIf="!isLoading">
    <mat-list-item *ngFor="let room of rooms" (click)="selectRoom(room.id)">
      <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="space-between">
        <span class="ellipsis">{{ room.name }}</span>
        <span style="opacity: 0.5">{{ '(' + room.shortId + ')' }}</span>
      </div>
    </mat-list-item>
  </mat-action-list>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end">
  <button mat-flat-button matDialogClose>
    {{ 'dialog.close' | transloco }}
  </button>
</mat-dialog-actions>
