<h2 mat-dialog-title tabindex="0">
  {{ 'cookies.title' | transloco }}
</h2>
<mat-dialog-content tabindex="-1">
  <div>
    <p tabindex="0">
      {{ 'cookies.intro' | transloco }}
      {{ 'cookies.intro-privacy1' | transloco }}
      <a href="{{ privacyUrl }}" target="_blank">{{
        'cookies.privacy-policy' | transloco
      }}</a>
      {{ 'cookies.intro-privacy2' | transloco }}
    </p>
    <div>
      <div class="cookie-cat" *ngFor="let category of categories">
        <mat-slide-toggle
          labelPosition="before"
          [(ngModel)]="category.consent"
          [disabled]="category.required || !!category.disabled"
        >
          <div
            class="slide-label"
            [attr.aria-label]="'cookies.a11y-' + category.id | transloco"
          >
            {{ 'cookies.category-' + category.id | transloco }}
          </div>
        </mat-slide-toggle>
        <div class="cookie-cat-desc">
          {{ 'cookies.category-' + category.id + '-desc' | transloco }}
        </div>
      </div>
    </div>
  </div>
  <p>
    {{ 'cookies.update-later' | transloco }}
  </p>
</mat-dialog-content>
<div mat-dialog-actions fxLayout="row wrap" fxLayoutAlign="end">
  <button
    mat-stroked-button
    (click)="acceptSelectedCookies()"
    [attr.aria-label]="
      'cookies.a11y-accept-selected'
        | transloco
          : {
              functional: categories[1].consent
                ? ''
                : ('cookies.not' | transloco),
              statistic: categories[2].consent
                ? ''
                : ('cookies.not' | transloco)
            }
    "
  >
    {{ 'cookies.cancel' | transloco }}
  </button>
  <button
    mat-flat-button
    color="primary"
    (click)="acceptAllCookies()"
    [attr.aria-label]="'cookies.a11y-accept' | transloco"
  >
    {{ 'cookies.accept' | transloco }}
  </button>
</div>
