<div
  fxLayout="row wrap"
  fxLayoutAlign="center"
  class="link-container"
  [ngClass]="{ 'container-padding': showHelp }"
>
  <div fxLayout="row">
    <a *ngIf="isAdminRole()" routerLink="admin" (click)="sendClickedEvent()">{{
      'header.admin-area' | transloco
    }}</a>
    <a *ngIf="showHelp && helpUrl" [attr.href]="helpUrl" target="_blank">{{
      'header.help' | transloco
    }}</a>
    <a *ngIf="accessibilityUrl" [attr.href]="accessibilityUrl" target="_blank">
      {{ 'footer.accessibility' | transloco }}</a
    >
  </div>
  <div fxLayout="row">
    <a [matMenuTriggerFor]="privacyMenu"> {{ 'footer.dsgvo' | transloco }}</a>
    <a *ngIf="imprintUrl" [href]="imprintUrl" target="_blank">
      {{ 'footer.imprint' | transloco }}</a
    >
  </div>
</div>
<mat-menu #privacyMenu="matMenu" [overlapTrigger]="false">
  <a *ngIf="privacyUrl" [attr.href]="privacyUrl" target="_blank" mat-menu-item>
    {{ 'footer.privacy-policy' | transloco }}</a
  >
  <button mat-menu-item (click)="showCookieSettings()">
    {{ 'footer.cookie-settings' | transloco }}
  </button>
</mat-menu>
