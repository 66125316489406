<div fxLayout="row" fxLayoutAlign="center" class="answer-container">
  <div fxLayout="column" fxLayoutGap="0.5em">
    <mat-form-field
      appearance="outline"
      class="no-field-hint form-field-indication"
      [ngClass]="{
        correct: showAnswerIndicator() && isCorrect(),
        wrong: showAnswerIndicator() && !isCorrect()
      }"
    >
      <mat-label>{{ 'answer.your-answer' | transloco }}</mat-label>
      <input
        [disabled]="isDisabled"
        [(ngModel)]="selectedNumber"
        (ngModelChange)="emitSelectedNumber()"
        matInput
        type="number"
        [min]="content.minNumber"
        [max]="content.maxNumber"
        name="'answer'"
      />
      <mat-icon
        matSuffix
        *ngIf="showAnswerIndicator()"
        class="icon-indicator"
        [ngClass]="isCorrect() ? 'correct' : 'wrong'"
      >
        {{ isCorrect() ? 'check' : 'close' }}</mat-icon
      >
    </mat-form-field>
    <span *ngIf="!answer" class="answer-hint"
      >{{
        'answer.select-a-number-between'
          | transloco
            : {
                min: content.minNumber | localizeDecimalSeperator,
                max: content.maxNumber | localizeDecimalSeperator
              }
      }}
    </span>
    <span *ngIf="showAnswerIndicator()" class="answer-hint"
      >{{ 'answer.correct-answer-is' | transloco }}
      <b>{{ content.correctNumber }}</b>
      <span *ngIf="content.tolerance"> &plusmn; {{ content.tolerance }}</span>
    </span>
  </div>
</div>
