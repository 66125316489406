<mat-panel-title>
  <span
    class="title"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="10px"
  >
    <mat-icon>{{ icon }}</mat-icon>
    <span>{{ text | transloco }}</span>
  </span>
</mat-panel-title>
