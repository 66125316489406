<div fxLayout="row" fxLayoutAlign="space-between center">
  <span>
    {{ data.message }}
  </span>
  <mat-spinner
    *ngIf="data.icon === LOADING_ICON; else showIcon"
    diameter="24"
  ></mat-spinner>
  <ng-template #showIcon
    ><mat-icon class="{{ data.icon }}">{{ data.icon }}</mat-icon></ng-template
  >
</div>
