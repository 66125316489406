<div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="start center">
  <mat-icon fxFlex="noshrink" class="{{ hint.class }}">{{
    hint.icon
  }}</mat-icon>
  <p *ngIf="text; else showContent" class="smaller-text">
    {{ text | transloco }}
  </p>
  <ng-template #showContent>
    <ng-content></ng-content>
  </ng-template>
</div>
