<div fxLayout="column" fxLayoutAlign="center center" fxFill>
  <mat-card class="login-card" fxLayout="column">
    <p class="info" fxFlexAlign="start">
      {{ 'password-reset.set-new-password' | transloco }}
    </p>
    <div fxLayout="row">
      <p>{{ 'password-reset.your-email' | transloco }}{{ email }}</p>
    </div>
    <form
      class="full-width"
      fxLayout="column"
      fxLayoutAlign="space-around"
      fxLayoutGap="10px"
    >
      <app-password-entry
        [checkStrength]="true"
        [isNew]="true"
      ></app-password-entry>
      <mat-form-field class="input-block" appearance="outline">
        <mat-label>{{ 'password-reset.key' | transloco }}</mat-label>
        <input
          matInput
          type="text"
          #key
          [formControl]="keyFormControl"
          [errorStateMatcher]="matcher"
        />
        <mat-error *ngIf="keyFormControl.hasError('required')">
          {{ 'password-reset.key-required' | transloco }}
        </mat-error>
      </mat-form-field>
      <app-loading-button
        name="password-reset.set-new-password-button"
        [fullWidth]="true"
        (clicked)="setNewPassword(key.value)"
      ></app-loading-button>
    </form>
  </mat-card>
</div>
