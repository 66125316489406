<b mat-dialog-title tabindex="0">{{ 'hotkeys.overview-title' | transloco }}</b>
<div mat-dialog-content>
  <table mat-table [dataSource]="hotkeys">
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef tabindex="0">
        {{ 'hotkeys.action' | transloco }}
      </th>
      <td mat-cell *matCellDef="let item">
        {{ item.actionTitle | transloco }}
        <span
          *ngIf="item.actionType === HotkeyActionType.INPUT"
          class="action-type-hint"
          >({{ 'hotkeys.input-field' | transloco }})</span
        >
      </td>
    </ng-container>
    <ng-container matColumnDef="keys">
      <th mat-header-cell *matHeaderCellDef tabindex="0">
        {{ 'hotkeys.keys' | transloco }}
      </th>
      <td mat-cell *matCellDef="let item">
        <ng-container *ngFor="let modifier of item.modifiers"
          ><kbd>{{ 'keys.' + modifier | transloco }}</kbd> +</ng-container
        >
        <kbd *ngIf="item.translateKeyName; else rawKey">{{
          'keys.' + item.keyName | transloco
        }}</kbd>
        <ng-template #rawKey
          ><kbd>{{ item.keySymbol }}</kbd></ng-template
        >
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      tabindex="0"
    ></tr>
  </table>
</div>
