<h3
  *ngIf="showTitle"
  tabindex="0"
  [appAutofocus]
  [attr.aria-label]="'creator.content.a11y-preview-header' | transloco"
>
  {{
    (content.format !== 'FLASHCARD'
      ? 'creator.content.preview'
      : 'creator.content.flashcard-front'
    ) | transloco
  }}
</h3>
<mat-card appearance="outlined">
  <app-rendered-text
    tabindex="0"
    [rawText]="content.body"
    [dynamic]="true"
    [markdownFeatureset]="markdownFeatureset"
  ></app-rendered-text>
  <lib-extension-point
    extensionId="attachment-list"
    [extensionData]="attachmentData"
  ></lib-extension-point>
  <app-content-choice-answer
    *ngIf="
      content.format === 'CHOICE' ||
      content.format === 'SCALE' ||
      content.format === 'BINARY'
    "
    [selectableAnswers]="selectableAnswers"
    [multipleAnswersAllowed]="multipleAnswers"
    [dynamicRendering]="content.format === 'CHOICE' && renderAnswersDynamically"
  ></app-content-choice-answer>
  <app-content-sort-answer
    *ngIf="content.format === 'SORT'"
    [answerOptions]="answerOptions"
    [dynamicRendering]="renderAnswersDynamically"
  ></app-content-sort-answer>
  <app-content-wordcloud-answer
    *ngIf="content.format === 'WORDCLOUD'"
    [words]="words"
  ></app-content-wordcloud-answer>
  <app-content-prioritization-answer
    *ngIf="content.format === 'PRIORITIZATION' && assignablePoints"
    [answerOptions]="answerOptionsWithPoints"
    [assignablePoints]="assignablePoints"
  ></app-content-prioritization-answer>
  <app-content-text-answer
    *ngIf="content.format === 'TEXT'"
  ></app-content-text-answer>
  <app-content-numeric-answer
    *ngIf="content.format === 'NUMERIC' && numericContent"
    [content]="numericContent"
  ></app-content-numeric-answer>
</mat-card>
<div *ngIf="content.format === 'FLASHCARD'">
  <h3>
    {{ 'creator.content.flashcard-back' | transloco }}
  </h3>
  <mat-card appearance="outlined">
    <app-rendered-text
      *ngIf="additionalText"
      [rawText]="additionalText"
      [dynamic]="true"
      [markdownFeatureset]="markdownFeatureset"
    >
    </app-rendered-text>
  </mat-card>
</div>
