<button
  *ngIf="isDialog; else raisedButtons"
  mat-flat-button
  type="submit"
  [color]="color"
  [disabled]="(formDisabled && loading) || disabled"
  [attr.aria-label]="aria ?? name | transloco"
  (click)="click()"
>
  <ng-template [ngTemplateOutlet]="innerButton"></ng-template>
</button>
<ng-template #raisedButtons>
  <button
    *ngIf="!useDirectives; else withDirective"
    mat-raised-button
    type="submit"
    [color]="color"
    [disabled]="(formDisabled && loading) || disabled"
    [ngClass]="{ 'login-button': fullWidth }"
    [attr.aria-label]="aria ?? name | transloco"
    (click)="click()"
  >
    <ng-template [ngTemplateOutlet]="innerButton"></ng-template>
  </button>
  <ng-template #withDirective>
    <button
      *ngIf="hotkey && trackInteraction"
      mat-raised-button
      type="submit"
      [color]="color"
      [disabled]="(formDisabled && loading) || disabled"
      [ngClass]="{ 'login-button': fullWidth }"
      [appHotkey]="hotkey"
      [appHotkeyTitle]="hotkeyTitle"
      [appHotkeyAction]="hotkeyAction"
      [appTrackInteraction]="trackInteraction"
      [appTrackName]="trackName"
      [attr.aria-label]="aria ?? name | transloco"
      (click)="click()"
    >
      <ng-template [ngTemplateOutlet]="innerButton"></ng-template>
    </button>
  </ng-template>
</ng-template>

<ng-template #innerButton>
  <span [ngClass]="{ hidden: formDisabled && loading }">
    {{ name | transloco }}
  </span>
  <app-loading-indicator
    *ngIf="formDisabled && loading"
    [size]="18"
    [disabled]="true"
  ></app-loading-indicator>
</ng-template>
