<div
  [ngStyle]="{ 'min-height': height }"
  fxLayout="column"
  fxLayoutAlign="center"
>
  <div
    fxLayout="row"
    fxLayoutAlign="center"
    class="loading-indicator-container"
  >
    <mat-progress-spinner
      [ngClass]="{ 'disabled-spinner': disabled }"
      mode="indeterminate"
      diameter="{{ size }}"
    ></mat-progress-spinner>
  </div>
</div>
