<mat-form-field
  class="input-block"
  [ngClass]="{ 'no-field-hint': !checkStrength }"
  appearance="outline"
>
  <mat-label>{{
    (isNew ? 'password.new-password' : 'password.password') | transloco
  }}</mat-label>
  <input
    matInput
    type="password"
    #passwordInput
    [type]="hidePw ? 'password' : 'text'"
    [(ngModel)]="password"
    [formControl]="passwordFormControl"
    [errorStateMatcher]="matcher"
    autocomplete="{{ isNew ? 'new-password' : 'current-password' }}"
    (input)="checkInput()"
    (blur)="activateValidators()"
  />
  <button
    *ngIf="showPwButton || isNew"
    mat-icon-button
    matSuffix
    type="button"
    (click)="hidePw = !hidePw"
    [matTooltip]="
      (hidePw ? 'password.show-password' : 'password.hide-password') | transloco
    "
  >
    <mat-icon>{{ hidePw ? 'visibility_off' : 'visibility' }}</mat-icon>
  </button>
  <mat-error *ngIf="checkStrength && passwordFormControl.hasError('required')">
    {{ 'password.password-required' | transloco }}
  </mat-error>
  <mat-error
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="5px"
    *ngIf="
      checkStrength &&
      !passwordFormControl.hasError('required') &&
      passwordFormControl.hasError('minlength')
    "
  >
    <mat-icon>do_not_disturb_on</mat-icon>
    <span>
      {{ 'password.password-too-short' | transloco }}
    </span>
  </mat-error>
  <mat-error
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="5px"
    *ngIf="
      checkStrength &&
      !passwordFormControl.hasError('minlength') &&
      passwordFormControl.hasError('validatePasswordStrength') &&
      strength === strengthLevels.WEAK
    "
  >
    <mat-icon>do_not_disturb_on</mat-icon>
    <span>
      {{ 'password.password-too-weak' | transloco }}
    </span>
  </mat-error>
  <mat-hint
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="5px"
    class="warn-soft"
    *ngIf="
      checkStrength &&
      passwordFormControl.valid &&
      strength >= strengthLevels.OKAY &&
      strength < strengthLevels.STRONG
    "
  >
    <mat-icon>check_circle</mat-icon>
    <span>
      {{ 'password.password-okay' | transloco }}
    </span>
  </mat-hint>
  <mat-hint
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="5px"
    class="success"
    *ngIf="
      checkStrength &&
      passwordFormControl.valid &&
      strength >= strengthLevels.STRONG
    "
  >
    <mat-icon>verified</mat-icon>
    <span>
      {{ 'password.password-strong' | transloco }}
    </span>
  </mat-hint>
</mat-form-field>
