<div fxLayout="column" fxLayoutAlign="center center" fxFill>
  <form (ngSubmit)="joinRoom(roomCode.value)">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
      <mat-form-field
        (keyup.enter)="onEnter(roomCode.value)"
        appearance="outline"
        class="no-field-hint"
        floatLabel="always"
      >
        <mat-label>{{ 'home-page.room-id' | transloco }}</mat-label>
        <input
          id="room-id-input"
          class="join-input"
          matInput
          #roomCode
          type="text"
          inputmode="numeric"
          [appAutofocus]="focusInput"
          (beforeinput)="enforceLengthLimit($event)"
          (input)="handleInput($event)"
          [formControl]="roomCodeFormControl"
          [errorStateMatcher]="matcher"
          [attr.aria-label]="
            'home-page.a11y-welcome-message' | transloco: { title: appTitle }
          "
          autocomplete="off"
          placeholder="{{ 'home-page.example' | transloco }}{{
            '11223344' | splitShortId: false
          }}"
          appHotkey="1"
          [appHotkeyTitle]="'home-page.enter-room-number' | transloco"
        />
        <button
          id="join-button"
          mat-mini-fab
          color="primary"
          class="join-button"
          type="submit"
          role="button"
          (click)="joinRoom(roomCode.value)"
          [attr.aria-label]="'home-page.a11y-join-button' | transloco"
          matSuffix
        >
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </form>
</div>
