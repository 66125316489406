<form (ngSubmit)="addContentGroup()">
  <div
    mat-dialog-content
    fxLayout="column"
    fxLayoutAlign="center"
    fxLayoutGap="10px"
  >
    <mat-form-field appearance="outline">
      <mat-label>{{
        'creator.dialog.content-group-name' | transloco
      }}</mat-label>
      <input
        [disabled]="formDisabled"
        matInput
        #nameInput
        class="input-block"
        type="text"
        maxlength="50"
        [(ngModel)]="name"
        name="contentGroupName"
        autocomplete="off"
        [placeholder]="'creator.dialog.content-group-example' | transloco"
      />
      <mat-hint align="end"
        ><span aria-hidden="true"
          >{{ nameInput.value.length }} / 50</span
        ></mat-hint
      >
    </mat-form-field>
  </div>
  <div fxLayoutAlign="end">
    <div mat-dialog-actions fxLayout="row-reverse" fxLayoutAling="end">
      <app-loading-button
        name="creator.dialog.create-content-group"
        [isDialog]="true"
      >
      </app-loading-button>
      <button
        (click)="closeDialog()"
        aria-labelledby="cancel"
        mat-button
        type="button"
      >
        {{ 'dialog.cancel' | transloco }}
      </button>
    </div>
  </div>
</form>
