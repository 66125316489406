<mat-card [ngClass]="{ disabled: editMode }" appearance="outlined">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <h3 class="title">{{ announcement.title }}</h3>
    <div
      fxLayoutAlign="center center"
      *ngIf="!!label"
      class="label-container secondary-background"
    >
      <span class="label-text new-label">{{
        'announcement.' + label | transloco
      }}</span>
    </div>
    <button
      mat-icon-button
      *ngIf="role === 'OWNER'"
      [matMenuTriggerFor]="moreMenu"
      [disabled]="editMode"
      [matTooltip]="'creator.announcement.options' | transloco"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #moreMenu>
      <button mat-menu-item (click)="edit()">
        <mat-icon>edit</mat-icon>
        {{ 'creator.announcement.edit' | transloco }}
      </button>
      <button mat-menu-item (click)="delete()">
        <mat-icon color="warn">delete</mat-icon>
        {{ 'creator.announcement.delete' | transloco }}
      </button>
    </mat-menu>
  </div>
  <p class="subtitle">
    <app-date
      [timestamp]="
        announcement.updateTimestamp || announcement.creationTimestamp
      "
      [responsive]="true"
    ></app-date>
    <span *ngIf="announcement.updateTimestamp"
      >&nbsp;({{ 'announcement.edited' | transloco }})</span
    >
  </p>
  <div>
    <app-rendered-text
      [rawText]="announcement.renderedBody"
      [markdownFeatureset]="markdownFeatureset"
    ></app-rendered-text>
  </div>
  <div fxLayoutAlign="start">
    <div
      fxLayoutAlign="center center"
      *ngIf="role !== 'OWNER'"
      class="label-container room-container"
    >
      <span class="label-text room-label">{{ roomName }}</span>
    </div>
  </div>
</mat-card>
