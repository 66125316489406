<form [formGroup]="formGroup" fxLayout="column" fxLayoutGap="20px">
  <mat-form-field [appAutofocus]="true" appearance="outline">
    <mat-label>{{ 'templates.name' | transloco }}</mat-label>
    <input
      id="nameInput"
      #nameInput
      matInput
      maxlength="50"
      [(ngModel)]="name"
      autocomplete="off"
      formControlName="name"
    />
    <mat-hint align="end"
      ><span aria-hidden="true"
        >{{ nameInput.value.length || 0 }} / 50</span
      ></mat-hint
    >
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>{{ 'templates.description' | transloco }}</mat-label>
    <textarea
      id="descriptionInput"
      #descriptionInput
      matInput
      [(ngModel)]="description"
      cdkTextareaAutosize
      cdkAutosizeMinRows="3"
      cdkAutosizeMaxRows="10"
      maxlength="250"
      formControlName="description"
    ></textarea>
    <mat-hint align="end"
      ><span aria-hidden="true"
        >{{ descriptionInput.value.length || 0 }} / 250</span
      ></mat-hint
    >
  </mat-form-field>
  <mat-form-field appearance="outline" class="no-field-hint">
    <mat-label>{{ 'templates.license' | transloco }}</mat-label>
    <mat-select
      panelClass="bigger-panel"
      [value]="selectedLicense"
      formControlName="licenses"
    >
      <mat-select-trigger>
        {{ LICENSES.get(selectedLicense)?.name }}
      </mat-select-trigger>
      <mat-option
        *ngFor="let license of licenseKeys"
        [value]="license"
        (click)="updateLicense(license)"
      >
        <div class="license-option" fxLayout="column" fxLayoutGap="4px">
          <span class="license-name"> {{ LICENSES.get(license)?.name }}</span>
          <span class="license-description">
            {{
              'templates.license-description-' + replaceDots(license)
                | transloco
            }}
          </span>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field
    class="no-field-hint"
    [appAutofocus]="true"
    appearance="outline"
  >
    <mat-label>{{
      ('templates.attribution' | transloco) +
        (selectedLicense === licenseKeys[0]
          ? ' (' + ('templates.optional' | transloco) + ')'
          : '')
    }}</mat-label>
    <input
      id="attributionInput"
      #nameInput
      matInput
      [(ngModel)]="attribution"
      autocomplete="off"
      formControlName="attribution"
    />
  </mat-form-field>
  <app-template-language-selection
    (selectedLangChanged)="updateLanguage($event)"
  ></app-template-language-selection>
  <app-template-tag-selection
    (selectedTagsChanged)="updateTags($event)"
    [lang]="selectedLang"
    [langChanged]="langChanged"
    [selectedTags]="selectedTags"
    [allowCreation]="true"
  ></app-template-tag-selection>
  <mat-checkbox [checked]="aiGenerated" (change)="aiGenerated = !aiGenerated">
    {{ 'templates.is-ai-generated' | transloco }}</mat-checkbox
  >
</form>
