<mat-dialog-content fxLayout="column">
  <mat-form-field appearance="outline">
    <mat-label>{{ 'creator.export.file-type' | transloco }}</mat-label>
    <mat-select required [(ngModel)]="selectedExportType">
      <mat-option *ngFor="let exportType of exportTypes" [value]="exportType">{{
        exportType.label | transloco
      }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>{{ 'creator.export.charset' | transloco }}</mat-label>
    <mat-select required [(ngModel)]="selectedCharset">
      <mat-option *ngFor="let charset of charsets" [value]="charset">{{
        charset
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>

<div mat-dialog-actions fxLayout="row wrap" fxLayoutAlign="end">
  <button mat-button (click)="cancel()">
    {{ 'dialog.cancel' | transloco }}
  </button>
  <button
    mat-flat-button
    color="primary"
    class="accept-button"
    (click)="export()"
  >
    {{ 'creator.export.export' | transloco }}
  </button>
</div>
