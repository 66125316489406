<div
  class="visually-hidden"
  tabindex="-1"
  [appAutofocus]="this.deviceType !== 'desktop'"
>
  {{ 'home-page.a11y-welcome-message' | transloco: { title: appTitle } }}
</div>
<div fxLayout="column" class="container">
  <div class="heading" fxLayout="column" fxLayoutAlign="center center">
    <div class="logo-container">
      <lib-extension-point extensionId="app-logo"></lib-extension-point>
    </div>
    <lib-extension-point extensionId="branding-title"></lib-extension-point>
  </div>
  <div fxLayout="row" fxLayoutAlign="center">
    <div fxLayout="row" fxLayoutAlign="center">
      <div fxLayout="column" fxLayoutAlign="center center" fxFill>
        <app-room-join
          [appTitle]="appTitle"
          [focusInput]="deviceType === 'desktop'"
        ></app-room-join>
        <button
          id="new-room-button"
          mat-button
          class="mat-button-classic"
          (click)="openCreateRoomDialog()"
          appHotkey="2"
          [appHotkeyTitle]="'home-page.create-room' | transloco"
        >
          <mat-icon>add</mat-icon>
          {{ 'home-page.create-room' | transloco }}
        </button>
      </div>
    </div>
  </div>
  <lib-extension-point extensionId="home-info"></lib-extension-point>
</div>
